import './App.css';
import React from 'react';
import Page from './Page'

function App() {
    return (
        <Page />
    );
}

export default App;